import { useEffect, useState, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis';
import useRootClose from 'react-overlays/useRootClose';


function PostSnapshot(props) {
    const post = props.post;
    const fbPost = post._fbPostId_?.split("_");
    const fbPageId= fbPost?.[0];
    const fbPostId= fbPost?.[1];
    
    var isUpComingPost = false;
    if(new Date(post.occurrenceDate) > new Date(new Date().toDateString())) {
        isUpComingPost = true;
    }

    //const [showPostDetails, setShowPostDetails] = useState(false);
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

    //const closePost = () => {
    //    setShowPostDetails(false);
    //}
    //const showPost = () => {
    //    setShowPostDetails(true);
    //}

    const deletePost = () => {
        props.deletePostCallback(post.id);
        setShowDeleteConfirmDialog(false);
    }

    //const postDetail = useRef();
    //useRootClose(postDetail, closePost, {
    //    disabled: !showPostDetails,
    //});

    useEffect(() => {
          
    },[]);

    return (
        <>

            <Card type={post.type} className='post mb-1' style={{ width: '20rem'}} onClick={props.onClick} >
                
                <Card.Header>
                    {new Date(post.occurrenceDate).toLocaleString("us-EN", {"dateStyle":"full", "timeStyle":"short"})}
                    {isUpComingPost ? <Image fluid src='/Upcoming.png' style={{maxHeight:'3rem', position:'absolute', top:'30', left:'0'}}/>:''}
                    <img src="/delete.png" alt='delete' 
                        onClick={(e) => setShowDeleteConfirmDialog(true)} 
                        style={{maxHeight:'25px', float:'right', display:props.userPrivileges?.includes('admin')?'':'none'}} 
                    />
                </Card.Header>
                
                <Card.Body  style={{ height: '8rem'}}>               
                    <Card.Title className="text-truncate">{post.title}</Card.Title>                    
                    <Row>
                        <Col>                           
                            <LinesEllipsis 
                                text={post.description}
                                maxLine={3}
                                ellipsis='...'
                                trimRight                               
                            />
                        </Col>  
                        { post.media.length > 0 ?
                        <Col xs={4}>
                            <Image fluid style={{height:'4.8rem', width:'4.8rem'}} src={process.env.REACT_APP_API_URL+"/obj"+post.media[0]} />
                        </Col>
                        :<></>
                        }
                    </Row>
                    
                </Card.Body>

                <Card.Footer style={{ height: '3rem'}}>
                    
                    <a 
                    style={{display:fbPost?'block':'none', width:'2rem', float:'right'}}
                    href={`https://www.facebook.com/${fbPageId}/posts/${fbPostId}`} 
                    target='_blank' rel='noreferrer'
                    >
                        <Image src='/facebook.png' className='btn-primary' style={{width:"2rem" }} roundedCircle/>
                    </a>
                    
                    
                </Card.Footer>

            </Card>
            {/*
            <Collapse in={showPostDetails} className='post-detail mt-1' >
                <Container ref={postDetail} className='text-center' >
                    <div style={{display:props.userPrivileges?.includes('admin')?'':'none'}}>posted by: {post._createdByUser_}</div>
                    {post.media.length >0 ?
                    <Carousel>
                        {
                            post.media.map((mediaUrl,idx) => (
                            <Carousel.Item key={idx}>
                                <Image fluid src={process.env.REACT_APP_API_URL+"/obj"+mediaUrl} />
                            </Carousel.Item>    
                            ))
                        }
                    </Carousel>
                    :<></>
                    }
                    <h4>{post.occurrenceDate}</h4>
                    <h3>{post.title}</h3>
                    <h4>{post.subtitle}</h4>
                    <p>{post.description}</p>
                </Container>

            </Collapse>
                */}        
            {/* Delete Confirmation Dialog */}
            <Modal 
                show={showDeleteConfirmDialog}
                onHide={(e) => setShowDeleteConfirmDialog(false)}
                size="sm" 
                backdrop="static"  
                centered           
            >
                <Modal.Header closeButton ></Modal.Header>
                <Modal.Body>
                    Confirm delete post.
                </Modal.Body>

                <Button variant='danger' className='mb-2' onClick={(e) => deletePost()}>Delete</Button>
                <Button variant='secondary' className='mb-2' onClick={(e) => setShowDeleteConfirmDialog(false)}>Cancel</Button>
                
            </Modal>
            
        </>
    );

}

export default PostSnapshot;