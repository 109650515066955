import { Container, Carousel, Image } from 'react-bootstrap';

function PostDetail(props) {
    const post = props.post

    return (
        <Container fluid className='text-center'>
            <div style={{display:props.userPrivileges?.includes('admin')?'':'none'}}>posted by: {post._createdByUser_}</div>
            
            <h4>{new Date(post.occurrenceDate).toLocaleString("us-EN", {"dateStyle":"full", "timeStyle":"short"})}</h4>
            <h3>{post.title}</h3>
            <h4>{post.subtitle}</h4>
            <p>{post.description}</p>

            {post.media.length >0 ?
            <Carousel>
                {
                    post.media.map((mediaUrl,idx) => (
                    <Carousel.Item key={idx}>
                        <Image fluid src={process.env.REACT_APP_API_URL+"/obj"+mediaUrl} />
                    </Carousel.Item>    
                    ))
                }
            </Carousel>
            :<></>
            }
        </Container>
    )
}

export default PostDetail