import { useRef, useState } from 'react'
import {  
    Card,
} 
from 'react-bootstrap'

function PictureInput(props) {
    const picSrc = props.src
    
    const picture = useRef()
    const [pictureSelected, setPictureSelected] = useState(false)


    const selectPicture = function (e) {
        const target = e.currentTarget
        const image = target.files[0];
        
        if(image) {
            picture.current.src = URL.createObjectURL(image);  
            setPictureSelected(true)        
        }else{
            picture.current.src = picSrc;
            setPictureSelected(false)            
        }
        
    }

    const unSelectPicture = function () {
        picture.current.src = picSrc;
        setPictureSelected(false)      
    }

    return (
        
        <Card bg='dark' text="light" style={{height:'14rem', width:'14rem', border:'0'}}>
            <Card.Img 
                style={{maxHeight:'14rem', maxWidth:'14rem' }} 
                variant="top" 
                src={picSrc}
                ref={picture}
            />
            <Card.Body>
                <div style={{display:pictureSelected?'none':'block'}}>
                    <img src="/edit.png" alt='upload' style={{top:'0', left:'0', position:'absolute', maxHeight:'30px'}} />
                    <label style={{top:'0', left:'0', position:'absolute', height:'30px', width:'30px'}}>
                        <input type='file' id={props.id} accept='image/*' hidden onChange={(e) => selectPicture(e)} /> 
                    </label>
                </div>
                <img 
                    src="/Cancel.png" 
                    alt='cancel' 
                    style={{top:'0', left:'0', position:'absolute', maxHeight:'30px', display:pictureSelected?'block':'none'}} 
                    onClick={unSelectPicture}
                />
                
            </Card.Body>
            
        </Card>
        
    )

}

export default PictureInput;